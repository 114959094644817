@import "colors.scss";

$logow: 4rem;
$logoh: 1.5rem;

body,
html {
	font-family: "EasyReadingPRO", Georgia;
	line-height: 1.84;
	background-image: -ms-radial-gradient(center, circle closest-side, #AFCC5E 0%, #38A962 100%);
	background-image: -moz-radial-gradient(center, circle closest-side, #AFCC5E 0%, #38A962 100%);
	background-image: -webkit-gradient(radial, center center, 0, center center, 140, color-stop(0, #AFCC5E), color-stop(1, #38A962));
	background-image: -webkit-radial-gradient(center, circle closest-side, #AFCC5E 0%, #38A962 100%);
	background-repeat: repeat-y;
}

button {
	font-family: "EasyReadingPRO", Georgia;
}

.logo {
	height: 6rem;
}

.container {
	position: relative;
	box-sizing: border-box;

}
