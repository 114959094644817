@import "colors.scss";

$logow: 4rem;
$logoh: 1.5rem;

$homebtn: #3c3c3b;

body,
html {
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
	/* Chrome all / Safari all */
	-moz-user-select: none;
	/* Firefox all */
	-ms-user-select: none;
	/* IE 10+ */
	user-select: none;
	/* Likely future */
}

.page {
	display: grid;
	place-items: center;
	width: 100vw;
	min-height: 100vh;
}

.container {
	position: relative;
	box-sizing: border-box;
	margin: auto !important;

	&.with-logo {
		&::before {
			content: "";
			background-image: url("./image/logo.svg");
			background-size: 100% 100%;
			position: absolute;
			top: -2rem;
			left: 0;
			color: white;
			width: $logow * 3;
			height: $logoh * 3;
		}
	}

	&.big {
		&::before {
			top: -2.5rem;
		}
	}
}

.banner {
	text-transform: uppercase;
	text-align: center;

	h2 {
		font-size: 1.5rem;
	}

	.logo {
		height: 8rem;
	}
}

.btn-start,
.btn-finish {
	background-color: $homebtn;
	border: none;
	border-radius: 50px;
	height: 3rem;
	width: 8rem;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1rem;
	background-color: $homebtn;
	position: relative;
	cursor: pointer;

	&::before {
		position: absolute;
		display: block;
		content: "";
		border-radius: 50px;
		border: 2px solid white;
		z-index: 1;
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
	}
}

.btn-finish {
	width: 12rem !important;
	height: 3rem !important;
	border-radius: 50px !important;
	margin-top: 10px;

	&::before {
		border-radius: 50px !important;
	}
}

.question,
.classifica {
	text-align: center;
}

.classifica h2 {
	margin-top: 0 !important;
	font-size: 22px;
}

.classifica .div-planets img.planets {
	//	width: 38%;
}

.answer {
	cursor: pointer;
	padding: 0.2rem;
	border: 1px solid black;
	margin-bottom: 0.6rem;

	&:hover {
		background-color: rgba(0, 0, 0, .15);
	}
}

.status-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 26rem;
	display: block;
	margin: 0 auto;
	right: 0;
}

.circle {
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 0.5rem;
	background: rgba(0, 0, 0, 0.15);
	display: inline-block;
	margin: 0 0.2rem;
	transition: all 0.2s ease-in-out;

	&.colored {
		background-color: $primary;
	}

	&.focus {
		background: rgba(0, 0, 0, 0.25);
		transform: scale(1.1);
	}
}

.suggestion {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (orientation: portrait) {
	.btn-start {
		margin-top: 5%;
	}

	.banner .logo {
		height: 6rem;
	}

	.page {
		align-items: baseline;
	}

	.status-bar {
		padding: 0 2rem;
		width: unset;
	}

	.container {
		width: calc(100vw - 2rem);
		padding: 2rem 2rem 3rem 2rem;
		background: white;

		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: -15rem;
			left: 0;
			width: 100%;
			height: 15rem;
			background-image: url("./image/fumetto-b.svg");
			background-size: 100% auto;
			background-position: center -1px;
			background-repeat: no-repeat;
		}

		&.with-logo {
			margin-top: 4.5rem;

			&::before {
				top: -4.5rem;
				left: 0;
				width: $logow * 2.5;
				height: $logoh * 2.5;
			}
		}
	}

	.question {
		font-size: 1.2rem;
	}

	.answers {
		font-size: 1rem;
		margin-top: 1rem;
	}

	.classifica .div-planets img.planets {
		//height: 4rem;
	}
}

@media (orientation: landscape) {
	.btn-start {
		margin-top: 1%;
	}

	.suggestion p {
		margin: 0 1rem;
	}

	.container {
		width: 45rem;
		height: 38rem;
		background-image: url("./image/fumetto-l.svg");
		background-size: 100% 100%;
		padding: 6rem 5rem 10rem 5rem;

		&.big {
			background-image: url("./image/fumetto-big.svg") !important;
			width: 68rem !important;
			height: 39rem !important;
			padding: 4rem 5rem 7rem 5rem;
		}

	}

	.status-bar {
		bottom: 12rem;
	}

	.question {
		font-size: 1.1rem;
	}

	.answers {
		text-align: center;
		font-size: 0.8rem;
		width: 15rem;
		margin: 1rem auto 0 auto
	}

	.answer-action:hover {
		background-color: rgba(0, 0, 0, 0.05) !important;
	}
}


.q-number {
	text-align: center;

	strong {
		font-size: 115%;
	}
}

@media screen and (orientation: landscape) {
	.q-number {
		position: absolute;
		top: 3rem;
		right: 4rem;
	}
}

.btn-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.classifica .btn-container button {
	width: 15rem !important;
}

.btn-container-no-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;

	.btn-finish {
		margin: 0.5rem 1rem;
	}
}

.scrollbar {
	width: 98%;
	max-height: 18rem;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		margin-left: 10px;
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0px grey;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: grey;
		border-radius: 10px;
	}

	/* Handle on hover */
	// &::-webkit-scrollbar-thumb:hover {
	// 	background: #b30000; 
	// }
}